import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "components/headers/light.js";
import Features from "components/features/BijleeFeatures";
import Features1 from "components/features/BijleeStatsPrimaryBackground";
import Pricing from "components/pricing/BijleePricing";
//import Testimonial from "components/testimonials/ThreeColumnWithProfileImage.js";
import Footer from "components/footers/FiveColumnWithInputFormUSPL.js";
import FAQ from "components/faqs/BijleeFAQs.js";
import { Helmet } from "react-helmet";

export default () => {
  return (
    <AnimationRevealPage>
      <Helmet>
                <meta charSet="utf-8" />
                <title>Free Solar for Homes</title>
                <meta name="description" content="Install solar with no upfront costs. Choose from our bank funded program or our propreitary USPL plans to lock in your electricity bill now! Get a free quote today." />
                <link rel="canonical" href="https://www.usplsolar.com/components/innerPages/BijleePage" />
            </Helmet>
      <Header>
      </Header>
      <Features />
      <Features1 />
      <Pricing />
      <FAQ />
      <Footer/>
    </AnimationRevealPage>
  );
};
